import { TableBody, Box } from "@material-ui/core";
import { useEffect, useRef } from "react";
import $ from "jquery";
import "jquery-ui/ui/widgets/sortable";
import "jquery-ui/themes/base/all.css"

interface SortableListProps {
  children: React.ReactNode
  onSort: (ids: number[]) => void
  table?: boolean
}

type SortableIdRecord = {
  id: number
  order_by: number
}

const SortableList = ({ children, onSort, table }: SortableListProps) => {
  const refList = useRef<any>(null);
  const onUpdate = () => {
    const ele = $(refList.current) as any;

    // Here's where our data-id attribute from before comes
    // into play. toArray will return a sorted array of item ids:
    var ids = ele.sortable('toArray', { attribute: 'data-id' });

    // Now we can loop through the array of ids, find the
    // item in our array by its id (again, w/ lodash),
    // and update its position:
    const newdata: SortableIdRecord[] = ids.map((id: any, index: number) => {
      return {
        id: id,
        order_by: index
      }
    });

    // We'll cancel the sortable change and let React reorder the DOM instead:
    ele.sortable('cancel');

    // After making our updates, we'll set our items
    // array to our updated array, causing items with
    // a new position to be updated in the DOM:
    newdata.sort((l, r) => {
      return l.order_by > r.order_by ? 1 : (l.order_by < r.order_by ? -1 : 0);
    });
    onSort(newdata.map(r => r.id));
  }
  useEffect(() => {
    if (refList && refList.current) {
      const ele = $(refList.current) as any;
      ele.sortable({
        items: '.sort-item',
        cancel: '.action-container',
        update: onUpdate
      });
    }
    // eslint-disable-next-line
  }, [refList]);

  return table ? <TableBody ref={refList}>
    {children}
  </TableBody> : <Box ref={refList}>
    {children}
  </Box>;
};

export default SortableList;

export const mapSortableData = (prev: any, ids: number[], idField='id') => {
  if (prev) {
    return ids.flatMap((id, idx) => {
      const match = prev.find((r: any) => `${r[idField]}` === `${id}`);
      if (match) {
        return [match];
      }
      return [];
    })
  }
  return [];
}