import { Grid, IconButton, Typography } from "@material-ui/core";
import { AddOutlined, ClearOutlined, FileUploadOutlined } from "@material-ui/icons";
import axios from "axios";
import { Fragment, ReactNode, useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { FormattedMessage } from "react-intl";
import { errorDetail } from "../../util/errorutil";
import Chip from "./Chip";

export type FileUploadItem = {
  file: File
  status: string | any | null
  isLoading: boolean
  isError: boolean
  isDone: boolean
}

export const notDoneFiles = (files: FileUploadItem[]) => {
  return files.filter(r => r.isDone === false);
}

export const uploadFiles = (token:string, files: FileUploadItem[], setFiles: React.Dispatch<React.SetStateAction<FileUploadItem[]>>, loading: boolean, setLoading: (v: boolean) => void, callback: (d: string | any) => Promise<any>, intl: any, url = '/api/upload') => {
  setLoading(true);
  const tmp = notDoneFiles(files);
  setFiles(tmp);
  Promise.all(tmp.map(async (f, idx) => {
    setFiles(tmp.map((r, i) => {
      if (i === idx) {
        r.isLoading = true;
        r.isError = false;
      }
      return r;
    }));
    const data = new FormData();
    data.append('file', f.file);
    return axios({
      method: 'post',
      url: url,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: data
    }).then((res: any) => {
        if (res.data.error) {
          setFiles(tmp.map((r, i) => {
            if (i === idx) {
              r.isLoading = false;
              r.isError = res.data.error;
              r.status = errorDetail(res.data, intl);
            }
            return r;
          }));
        } else {
          callback(res.data.data).then(rr => {
            setFiles(tmp.map((r, i) => {
              if (i === idx) {
                r.isLoading = false;
                r.isError = false;
                r.status = rr;
                r.isDone = true;
              }
              return r;
            }));
          }).catch(err => {
            setFiles(tmp.map((r, i) => {
              if (i === idx) {
                r.isLoading = false;
                r.isError = true;
                r.status = err;
              }
              return r;
            }));
          });
        }
      })
      .catch(err => {
        setFiles(tmp.map((r, i) => {
          if (i === idx) {
            r.isLoading = false;
            r.isError = true;
            r.status = intl.formatMessage({ id: 'error.general' });
          }
          return r;
        }));
      });
  })).then(ret => {
    setLoading(false);
  })
}
export enum FileType {
  Excel
}
interface FileUploadProps {
  multiple?: boolean
  drop?: boolean
  icon?: ReactNode
  fileType?: FileType,
  onUpload: () => void;
  files: FileUploadItem[]
  setFiles: React.Dispatch<React.SetStateAction<FileUploadItem[]>>
  showDone?: boolean
  mini?: boolean
}

const FileUpload = ({ multiple, drop, fileType, icon, onUpload, files, setFiles, showDone, mini }: FileUploadProps) => {
  const accept = fileTypeToAccept(fileType);
  const [key, setKey] = useState(0);
  const [forceUpload, setForceUpload] = useState(0);
  const allowMimeTypes = accept ? accept.split(',') : null;
  const multipleValue = multiple || false;
  const newFileUploadItem = (f: File) => {
    return {
      file: f,
      status: null,
      isLoading: false,
      isError: false,
      isDone: false
    }
  }
  //
  const selectFiles = (selectedFiles: FileUploadItem[]) => {
    if (multipleValue) {
      setFiles((prev) => ([...prev.filter(r => r.status === null), ...selectedFiles]));
    } else {
      setFiles((prev) => ([...selectedFiles]));
    }
    setForceUpload(prev => prev + 1);
  };
  useEffect(() => {
    mini && forceUpload > 0 && files && files.length > 0 && onUpload();
    // eslint-disable-next-line
  }, [forceUpload]);
  const deleteFile = (index: number) => {
    setFiles((prev) => prev.filter((r, idx) => idx !== index));
  };
  const deleteFiles = () => {
    setFiles([]);
  };
  //
  const selectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const selectedFiles = [];
      for (var f of e.target.files) {
        if (allowMimeTypes === null || allowMimeTypes.includes(f.type)) {
          selectedFiles.push(newFileUploadItem(f));
        }
      };
      selectFiles(selectedFiles);
      //reset file input
      setKey(key + 1);
    }
  };
  const onDrop = (acceptedFiles: File[]) => {
    selectFiles(acceptedFiles.map((f: File) => newFileUploadItem(f)));
  }
  const tmp = showDone ? files : notDoneFiles(files);
  return <div className={`file-upload ${mini ? 'file-upload-mini' : 'file-upload-normal'}`}>
    <div className="file-upload-top">
      <div className="file-upload-action">
        <IconButton size="small" color="secondary" component="label">{icon || <AddOutlined fontSize="inherit" />}
          <input type="file" key={key} multiple={multipleValue} accept={accept} hidden onChange={selectFile} />
        </IconButton>
        {!mini && <Fragment><IconButton size="small" color="secondary" onClick={onUpload}><FileUploadOutlined fontSize="inherit" /></IconButton>
          <IconButton size="small" color="secondary" onClick={deleteFiles}><ClearOutlined fontSize="inherit" /></IconButton></Fragment>}
      </div>
      {drop ? <Dropzone onDrop={onDrop} accept={accept} multiple={multiple}>
        {({ getRootProps, getInputProps, isDragActive }) => (
          <div className={`file-drop ${isDragActive ? 'active' : ''}`}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            {tmp.length === 0 || mini ? <div className="file-drop-text">{multipleValue ? <FormattedMessage id="label.drag-and-drop-or-select-files" /> : <FormattedMessage id="label.drag-and-drop-or-select-file" />}</div> : null}
          </div>
        )}
      </Dropzone> : null}
    </div>
    <div className="files">{tmp.map((file: FileUploadItem, index: number) => {
      return <Grid key={index} container spacing={1} wrap="nowrap" mb={1} mt={1}>
        <Grid item xs zeroMinWidth>
          <Typography noWrap className="file-name">{file.file.name}</Typography>
          <div className="color-secondary">{file.file.size} bytes</div>
          {file.isLoading ? <div>Loading...</div> : (file.status === null ? null : <Chip chipcolor={file.isError ? "error" : "secondary"} label={file.status} size="small" />)}
        </Grid>
        <Grid item>
          <IconButton size="small" color="secondary" onClick={() => deleteFile(index)}><ClearOutlined fontSize="inherit" /></IconButton>
        </Grid>
      </Grid>
    })}</div>
  </div>;
};

export default FileUpload;

const fileTypeToAccept = (fileType: FileType | undefined) => {
  switch (fileType) {
    case FileType.Excel:
      return 'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  }
  return fileType;
}