import { Box, Button, ClickAwayListener, Grid, List, ListItem, ListItemText, Paper, Popper, Typography } from "@material-ui/core";
import { Fragment, useEffect, useRef, useState } from "react";
import Transitions from "./Transitions";
import { Link } from 'react-router-dom';
import { KeyboardArrowDownOutlined } from '@material-ui/icons';
import { SelectOption } from '../../util/types';

export interface InputDropdown2Props {
    value: number
    options: SelectOption[];
    onChange: (value: number) => void
}

const InputDropdown2 = ({ value, options, onChange }: InputDropdown2Props) => {
    const [open, setOpen] = useState(false);
    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef<any>(null);

    const onChangeValue = (newvalue: number) => {
        onChange(newvalue);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: MouseEvent | TouchEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    return (
        <Fragment>
            <Box component="span" className="button-dropdown">
                <Button variant="text" color="secondary" endIcon={<KeyboardArrowDownOutlined />}
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                >
                    {options.find(r => r.id === value)?.label}
                </Button>
            </Box>
            <Popper
                placement={'bottom-end'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 10]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps, placement }) => (
                    <Transitions in={open} {...TransitionProps}>
                        <Paper elevation={16}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <List component="nav">
                                    {options.map((r, idx) => {
                                        if (r.link) {
                                            return <ListItem key={idx}
                                                button
                                                component={Link}
                                                to={r.link}
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Grid container>
                                                            {r.htmlLabel ? <Box>{r.htmlLabel}</Box> : <Typography color="textPrimary">{r.label}</Typography>}
                                                        </Grid>
                                                    }
                                                />
                                            </ListItem>;
                                        } else {
                                            return <ListItem key={idx}
                                                button
                                                selected={value === r.id}
                                                onClick={() =>
                                                    r.id && onChangeValue(r.id)
                                                }
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Grid container>
                                                            {r.htmlLabel ? <Box>{r.htmlLabel}</Box> : <Typography color="textPrimary">{r.label}</Typography>}
                                                        </Grid>
                                                    }
                                                />
                                            </ListItem>;
                                        }
                                    })}
                                </List>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </Fragment>
    );
};

export default InputDropdown2;