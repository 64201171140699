import React, { useState } from 'react';

// material-ui
import { Avatar, Box, ButtonBase, Card, CardContent, Grid, InputAdornment, InputBase, OutlinedInput, Popper } from '@material-ui/core';

// third-party
import PopupState, { bindPopper, bindToggle } from 'material-ui-popup-state';

// project imports
import Transitions from '../../../../common/Transitions';

// assets
import { IconSearch, IconX } from '@tabler/icons';
import { useIntl } from 'react-intl';

//-----------------------|| SEARCH INPUT ||-----------------------//

const SearchSection = () => {
    const intl = useIntl();
    const [value, setValue] = useState('');

    return (
        <React.Fragment>
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <PopupState variant="popper" popupId="demo-popup-popper">
                    {(popupState) => (
                        <React.Fragment>
                            <Box>
                                <ButtonBase className="btn-action">
                                    <Avatar {...bindToggle(popupState)}>
                                        <IconSearch className="icon-default" />
                                    </Avatar>
                                </ButtonBase>
                            </Box>
                            <Popper {...bindPopper(popupState)} transition className="header-search-popup">
                                {({ TransitionProps }) => (
                                    <>
                                        <Transitions type="zoom" {...TransitionProps} sx={{ transformOrigin: 'center left' }}>
                                            <Card>
                                                <CardContent>
                                                    <Grid container alignItems="center" justifyContent="space-between">
                                                        <Grid item xs>
                                                            <OutlinedInput fullWidth
                                                                className="header-search"
                                                                id="header-search-header"
                                                                value={value}
                                                                onChange={(e) => setValue(e.target.value)}
                                                                placeholder={intl.formatMessage({id:"label.search..."})}
                                                                startAdornment={
                                                                    <InputAdornment position="start">
                                                                        <IconSearch />
                                                                    </InputAdornment>
                                                                }
                                                                endAdornment={
                                                                    <InputAdornment position="end">
                                                                        <Box ml={2}>
                                                                            <ButtonBase>
                                                                                <Avatar 
                                                                                    {...bindToggle(popupState)}
                                                                                >
                                                                                    <IconX />
                                                                                </Avatar>
                                                                            </ButtonBase>
                                                                        </Box>
                                                                    </InputAdornment>
                                                                }
                                                                aria-describedby="search-helper-text"
                                                                inputProps={{
                                                                    'aria-label': 'weight'
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Transitions>
                                    </>
                                )}
                            </Popper>
                        </React.Fragment>
                    )}
                </PopupState>
            </Box>
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                <InputBase
                    className="header-search"
                    id="header-search-header"
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    placeholder="Search.."
                    startAdornment={
                        <InputAdornment position="start">
                            <IconSearch />
                        </InputAdornment>
                    }
                    aria-describedby="search-helper-text"
                    inputProps={{
                        'aria-label': 'weight'
                    }}
                />
            </Box>
        </React.Fragment>
    );
};

export default SearchSection;
