import { Box } from "@material-ui/core";

interface DotProps {
  text: string
  counts: number[]
}

const Dot = ({ counts, text }: DotProps) => {
  return <Box>
    {text}
    {counts.map((count, idx) => {
      return <span key={idx} className={`dot ${count>0?'dot-red':'dot-green'}`}>&bull;</span>;
    })}
  </Box>;
};

export default Dot;