import { Fragment } from 'react';
// material-ui
import { Box, Grid, IconButton } from '@material-ui/core';

// project imports
import LogoSection from '../LogoSection';
import SearchSection from './SearchSection';
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';
import LocalizationSection from './LocalizationSection';
import MobileSection from './MobileSection';

// assets
import { IconMenu2 } from '@tabler/icons';
import UserOptions from '../../../common/UserOptions';
import BaseData from '../../../common/BaseData';
import UserUpload from '../../../common/UserUpload';

//-----------------------|| MAIN NAVBAR / HEADER ||-----------------------//

export interface HeaderProps {
    handleLeftDrawerToggle: () => void;
    isAuthenticated: boolean;
}

const Header = ({ isAuthenticated, handleLeftDrawerToggle }: HeaderProps) => {
    return (
        <Fragment>
            {/* logo & toggler button */}
            <Box display="flex" alignItems="center" className="header-left">
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box>
                {isAuthenticated ? <IconButton className="btn-action btn-icon-sm" onClick={handleLeftDrawerToggle}>
                    <IconMenu2 />
                </IconButton> : null}
            </Box>
            {isAuthenticated ? <Box flexGrow={1} display="flex" alignItems="center" className="header-right">
                <Box flexGrow={1}>
                    <SearchSection></SearchSection>
                </Box>
                <BaseData>
                    <UserOptions />
                    <UserUpload />
                </BaseData>
                <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                    <Grid container justifyContent="flex-start" alignItems="center">
                        <LocalizationSection />
                        <NotificationSection />
                        <ProfileSection />
                    </Grid>
                </Box>

                {/* mobile header */}
                <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                    <MobileSection />
                </Box>
            </Box> : null}

        </Fragment>
    );
};

export default Header;
