import React from 'react';

// material-ui
import { useTheme } from '@material-ui/core/styles';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@material-ui/core';

// third-party
import clsx from 'clsx';

// project imports
import Header from './Header';
import Sidebar from './Sidebar';
import { useSharedState } from '../../../state';
import { useAuth0 } from '@auth0/auth0-react';
import { Toaster } from 'react-hot-toast';

//-----------------------|| MAIN LAYOUT ||-----------------------//

const MainLayout = ({ children }: { children: React.ReactElement | React.ReactElement[] }) => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const [state, setState] = useSharedState();

    // Handle left drawer
    const leftDrawerOpened = state.ui.opened;
    const handleLeftDrawerToggle = () => {
        setState((prev) => ({ ...prev, ui: { ...prev.ui, opened: !leftDrawerOpened } }));
    };
    const {
        isAuthenticated
    } = useAuth0();

    React.useEffect(() => {
        setState((prev) => ({ ...prev, ui: { ...prev.ui, opened: !matchDownMd } }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownMd]);
    return (
        <Box display="flex">
            <CssBaseline />
            {/* header */}
            <div><Toaster/></div>
            <AppBar position="fixed" elevation={0} className={clsx('header', leftDrawerOpened && 'with-nav')}>
                <Toolbar>
                    <Header isAuthenticated={isAuthenticated} handleLeftDrawerToggle={handleLeftDrawerToggle} />
                </Toolbar>
            </AppBar>
            {/* drawer */}
            {isAuthenticated?<Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />:null}

            {/* main content */}
            <Box flexGrow={1} component="main" 
                className={clsx('main-content', leftDrawerOpened && 'with-nav')}>
                {children}
            </Box>
        </Box>
    );
};

export default MainLayout;
