// material-ui
import {
    List,
    ListItem,
    ListItemText
} from '@material-ui/core';

// assets
import { Link } from 'react-router-dom';

//-----------------------|| NOTIFICATION LIST ITEM ||-----------------------//

const NotificationList = () => {
    return (
        <List className="list-notification">
            <ListItem className="list-item">
                <ListItemText>All done! Now check <Link to={"/"}>your inbox</Link> as you're in for a sweet treat!</ListItemText>
            </ListItem>
        </List>
    );
};

export default NotificationList;
