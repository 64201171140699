import React from 'react';

// material-ui
import { useTheme } from '@material-ui/core/styles';
import { Box, Drawer, useMediaQuery } from '@material-ui/core';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MenuList from './MenuList';
import LogoSection from '../LogoSection';
import BaseData from '../../../common/BaseData';

//-----------------------|| SIDEBAR DRAWER ||-----------------------//

export interface SidebarProps {
    drawerOpen?: boolean;
    drawerToggle?: () => void;
    window?: Window;
}

const Sidebar = ({ drawerOpen, drawerToggle, window }: SidebarProps) => {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

    const drawer = (
        <React.Fragment>
            <Box display="flex" flexDirection="column" className="sidebar-content">
                <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                    <Box display="flex" className="sidebar-logo">
                        <LogoSection />
                    </Box>
                </Box>
                <PerfectScrollbar component="div">
                    <BaseData>
                        <MenuList />
                    </BaseData>
                </PerfectScrollbar>
            </Box>
        </React.Fragment>
    );

    const container = window !== undefined ? () => window.document.body : undefined;

    return (
        <nav className="sidebar">
            <Drawer
                container={container}
                variant={matchUpMd ? 'persistent' : 'temporary'}
                anchor="left"
                open={drawerOpen}
                onClose={drawerToggle}
                classes={{
                    paper: "sidebar-paper"
                }}
                ModalProps={{ keepMounted: true }}
            >
                {drawer}
            </Drawer>
        </nav>
    );
};

export default Sidebar;
