import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

// material-ui
import {
    Avatar,
    Box,
    ClickAwayListener,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Popper
} from '@material-ui/core';

// project imports
import Transitions from '../../../../common/Transitions';
import { useAuth0 } from "@auth0/auth0-react";

// assets
import { IconLogout, IconUser } from '@tabler/icons';
import MainCard from '../../../../common/MainCard';
import { FormattedMessage } from 'react-intl';

//-----------------------|| PROFILE MENU ||-----------------------//

const ProfileSection = () => {
    const {
        logout, user
    } = useAuth0();
    const [open, setOpen] = React.useState(false);
    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = React.useRef<any>(null);
    const handleLogout = () => {
        try {
            logout({
                returnTo: window.location.origin,
            });
        } catch (err) {
            console.error(err);
        }
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const handleClose = (event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);


    let listItemProps: {
        component: React.ForwardRefExoticComponent<React.RefAttributes<HTMLAnchorElement>> | string;
    } = { component: React.useMemo(() => React.forwardRef((props, ref) => <RouterLink ref={ref} to={'/profile'} {...props} />), []) };

    return (
        <div>
            <Box component="span" className="link"
                ref={anchorRef}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                <Avatar
                    src={user?.picture}
                    ref={anchorRef}
                    aria-haspopup="true"
                    color="inherit"
                />
            </Box>
            <Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 16]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions in={open} {...TransitionProps}>
                        <ClickAwayListener onClickAway={handleClose}>
                            <MainCard className="dropdown">
                                <List component="nav">
                                    <ListItem {...listItemProps}
                                        button
                                        onClick={handleClose}
                                    >
                                        <ListItemIcon>
                                            <IconUser />
                                        </ListItemIcon>
                                        <ListItemText><FormattedMessage id="label.profile" /></ListItemText>
                                    </ListItem>
                                    <ListItem
                                        button
                                        onClick={handleLogout}
                                    >
                                        <ListItemIcon>
                                            <IconLogout />
                                        </ListItemIcon>
                                        <ListItemText><FormattedMessage id="action.logout" /></ListItemText>
                                    </ListItem>
                                </List>
                            </MainCard>
                        </ClickAwayListener>
                    </Transitions>
                )}
            </Popper>
        </div>
    );
};

export default ProfileSection;
