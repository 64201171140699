// project imports
import NavGroup from './NavGroup';
import { FormattedMessage, useIntl } from 'react-intl';
// assets
import { Box } from '@material-ui/core';
import { IconList, IconHelp, IconSettings, IconHome, IconLayersLinked } from '@tabler/icons';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useSharedState } from '../../../../../state';
import { Fragment, useEffect, useState } from 'react';
import Loader from '../../../../common/Loader';
import { Am_Job } from '../../../../../generated/graphql';
import { mapSortableData } from '../../../../common/SortableList';
import toast from 'react-hot-toast';
//-----------------------|| SIDEBAR MENU LIST ||-----------------------//

const MenuList = () => {
    const intl = useIntl();
    const [state,] = useSharedState();
    const [
        getJobs,
        { loading: loading_jobs, error: error_jobs, data: data_jobs, refetch: refetch_jobs }
    ] = useLazyQuery(gql`
        query ($organization_id: Int!) {
          data:am_job(where: {organization_id: {_eq: $organization_id}}, order_by: {order_by: asc}) {
            id
            job_status
            job_name_sources
          }
        }    
        `, {
        variables: {
            organization_id: state.organization?.id || 0
        },
        notifyOnNetworkStatusChange: true
    });
    useEffect(() => {
        getJobs();
    }, [getJobs]);

    const reloadJobs = () => {
        refetch_jobs && refetch_jobs();
    }
    useEffect(() => {
        if ((state.organization?.reload_jobs || 0) > 0) {
            reloadJobs();
        }
        // eslint-disable-next-line
    }, [state.organization?.reload_jobs]);
    //
    const [jobMenuItems, setJobMenuItems] = useState<any>([]);
    const [hasChanges, setHasChanges] = useState(false);
    const [loading, setLoading] = useState(false);
    //
    const [saveJobsOrder] = useMutation(gql`
    mutation ($data: UpdateJobsOrderInput!) {
        data:UpdateJobsOrder(data: $data){
            id
        }
    }   
    `);
    const save = () => {
        setLoading(true);
        saveJobsOrder({
            variables: {
                data: {
                    ids: (jobMenuItems || []).map((r: any) => r.extra_id),
                    locale: state.ui.locale
                }
            }
        }).then(ret => {
            setLoading(false);
            setHasChanges(false);
            toast.success(intl.formatMessage({id:"success.saved"}));
            reloadJobs();
        }).catch(err => {
            setLoading(false);
            toast.error(err?.message ?? 'error.general');
        });
    };

    useEffect(() => {
        hasChanges && save();
        //eslint-disable-next-line
    }, [hasChanges]);
    useEffect(() => {
        if (data_jobs) {
            setJobMenuItems(data_jobs.data.map((r: Am_Job) => {
                const url = `/job/${r.id}`;
                return {
                    id: url,
                    title: r.job_name_sources,
                    type: 'item',
                    url: url,
                    extra_css: `sort-item job-${r.job_status}`,
                    extra_id: r.id
                }
            }));
        }
    }, [data_jobs]);

    const onSort = (ids: number[]) => {
        setJobMenuItems((prev: any) => {
            return mapSortableData(prev, ids, 'extra_id');
        });
        setHasChanges(true);
    }
    const jobMenu = {
        id: 'jobs',
        title: <FormattedMessage id="label.jobs" />,
        type: 'collapse',
        icon: IconLayersLinked,
        sortable: true,
        onSort: onSort,
        children: jobMenuItems,
        ...(error_jobs?{
            caption: 'Error',
            error: true
        }:null)
    };
    const menuitems = [
        {
            id: 'dashboard',
            type: 'group',
            children: [
                {
                    id: '/',
                    title: <FormattedMessage id="label.dashboard" />,
                    type: 'item',
                    url: '/',
                    icon: IconHome
                }
            ]
        },
        {
            id: 'main',
            title: intl.formatMessage({id:"label.bookkeeping"}).toUpperCase(),
            type: 'group',
            children: [
                {
                    id: '/matching-row',
                    title: <FormattedMessage id="label.matching-rows" />,
                    type: 'item',
                    url: '/matching-row',
                    icon: IconList
                },
                jobMenu
            ]
        }];
    const other = {
        id: 'other',
        type: 'group',
        children: [
            {
                id: 'settings',
                title: <FormattedMessage id="label.settings" />,
                type: 'collapse',
                icon: IconSettings,
                children: [
                    {
                        id: '/organization',
                        title: <FormattedMessage id="label.organization" />,
                        type: 'item',
                        url: '/organization'
                    },
                    {
                        id: '/account',
                        title: <FormattedMessage id="label.accounts" />,
                        type: 'item',
                        url: '/account'
                    },
                    {
                        id: '/verification-template',
                        title: <FormattedMessage id="label.verification-templates" />,
                        type: 'item',
                        url: '/verification-template'
                    }
                ]
            },
            {
                id: '/help',
                title: <FormattedMessage id="label.help" />,
                type: 'item',
                url: '/help',
                icon: IconHelp
            }
        ]
    };
    const navItems = menuitems.map((item) => {
        return <NavGroup key={item.id} item={item} />;
    });

    return <Fragment>
        {loading || loading_jobs? <Loader/>:null}
        <Box display="flex" flexDirection="column" className="menu-container">
            <Box flexGrow={1}>{navItems}</Box>
            <NavGroup item={other} />
        </Box>
    </Fragment>;
};

export default MenuList;
