import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@material-ui/core';
import logo from './../../../../asset/image/logo.png';

//-----------------------|| MAIN LOGO ||-----------------------//

const LogoSection = () => {
    return (
        <ButtonBase disableRipple component={Link} to="/">
            <img src={logo} alt="Accountingbees"    />
        </ButtonBase>
    );
};

export default LogoSection;
