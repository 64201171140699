import { Box, Button, Grid } from "@material-ui/core";
import { useState } from "react";
import FileUpload, { FileType, FileUploadItem, notDoneFiles, uploadFiles } from "./FileUpload";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import toast from "react-hot-toast";
import { useIntl } from 'react-intl';
import { useAuth0 } from "@auth0/auth0-react";

const UserUpload = () => {
    const intl = useIntl();
    const [loading, setLoading] = useState<boolean>(false);
    const [files, setFiles] = useState<FileUploadItem[]>([]);
    const [url, setUrl] = useState<string>();
    //
    const { getAccessTokenSilently } = useAuth0();
    const [token, setToken] = useState<string>();
    const onUpload = async () => {
        setLoading(true);
        let tokenValue = token;
        if (tokenValue === undefined) {
            tokenValue = await getAccessTokenSilently();
            setToken(tokenValue);
        }
        uploadFiles(tokenValue, files, setFiles, loading, setLoading, (url) => {
            return new Promise((resolve, reject) => {
                setUrl(url);
                resolve(intl.formatMessage({ id: 'success.saved' }));
            });
        }, intl);
    }
    return <Box sx={{ display: 'flex' }} className={`header-upload ${url || notDoneFiles(files).length > 0 ? 'header-upload-full' : 'header-upload-empty'} ${url ? 'show-url' : ''}`}>
        <Grid container spacing={2} alignItems="center">
            <Grid item flexGrow={1}>
                <FileUpload mini={true} fileType={FileType.Excel} drop={true} files={files} setFiles={setFiles} onUpload={onUpload} />
                {url ? <Box className="copy-url">
                    <div className="url"><a href={url} rel="noreferrer" target="_blank">{url}</a></div>
                    <CopyToClipboard text={url}
                        onCopy={() => toast.success('Copied.')}>
                        <Button className="btn-link" size="small">Copy</Button>
                    </CopyToClipboard>
                    <Button className="btn-link" size="small" onClick={() => setUrl(undefined)}>Close</Button>
                </Box> : null}
            </Grid>
        </Grid>
    </Box>;
};

export default UserUpload;
