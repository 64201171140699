import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";

export function createApolloClient(authToken: string, dbUrl: string) {
  return new ApolloClient({
    link: new HttpLink({
      uri: dbUrl,
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    }),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            am_job_row: {
              merge(existing, incoming) {
                // Equivalent to what happens if there is no custom merge function.
                return incoming;
              },
            },
            am_job_rule: {
              merge(existing, incoming) {
                // Equivalent to what happens if there is no custom merge function.
                return incoming;
              },
            },
            am_job_source: {
              merge(existing, incoming) {
                // Equivalent to what happens if there is no custom merge function.
                return incoming;
              },
            },
            am_job_verification_template_cross: {
              merge(existing, incoming) {
                // Equivalent to what happens if there is no custom merge function.
                return incoming;
              },
            },
          },
        },
      },
    }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network'
      },
      query: {
        fetchPolicy: 'network-only'
      },
    },
  });
};