import { createTheme } from '@material-ui/core/styles';

// assets
import colors from '../asset/scss/_themes-vars.module.scss';

// project imports
import { componentStyleOverrides } from './compStyleOverride';
import { themePalette } from './palette';
import { themeTypography } from './typography';
import { customShadows } from './shadows';
import { ColorProps, CustomizationStateProps, SnackbarStateProps } from '../types';

// theme constant
export const gridSpacing = 3;
export const drawerWidth = 250;
export const appDrawerWidth = 320;

/**
 * Represent theme style and structure as per Material-UI
 * @param {JsonObject} customization customization parameter object
 */

 export const customization: CustomizationStateProps = {
    isOpen: [], //for active default menu
    fontFamily: `'Mukta', sans-serif`,
    borderRadius: 12,
    outlinedFilled: true,
    navType: 'light',
    presetColor: 'default',
    locale: 'en',
    rtlLayout: false,
    opened: true
};

export const snackbar: SnackbarStateProps = {
    action: false,
    open: false,
    message: 'Note archived',
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right'
    },
    variant: 'default',
    alertSeverity: 'success',
    transition: 'Fade',
    close: true,
    actionButton: false
};

let color: ColorProps = colors;

let themeOption = {
    colors: color,
    heading: color.grey900,
    paper: color.paper,
    backgroundDefault: color.paper,
    background: color.primaryLight,
    darkTextPrimary: color.grey900,
    darkTextSecondary: color.grey500,
    textDark: color.grey900,
    menuSelected: color.secondaryDark,
    menuSelectedBack: color.secondaryLight,
    divider: color.grey300,
    customization: customization
};

const theme = createTheme({
    direction: 'ltr',
    palette: themePalette(themeOption),
    mixins: {
        toolbar: {
            minHeight: '60px'
        }
    },
    customShadows: customShadows(customization.navType, themeOption),
    typography: themeTypography(themeOption),
    components: componentStyleOverrides(themeOption)
});

export default theme;
