import React from 'react';
import { Link } from 'react-router-dom';

// material-ui
import { Avatar, Chip, ListItem, ListItemIcon, ListItemText, Theme, Typography, useMediaQuery } from '@material-ui/core';

// project imports
import { LinkTarget } from '../../../../../../types';
import { NavItemType } from '../../../../../../types';

// assets
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { useSharedState } from '../../../../../../state';

//-----------------------|| SIDEBAR MENU LIST ITEMS ||-----------------------//

export interface NavItemProps {
    item: NavItemType;
    level: number;
}

const NavItem = ({ item, level }: NavItemProps) => {
    const [state, setState] = useSharedState();
    const matchesSM = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    const Icon = item?.icon!;
    const itemIcon = item?.icon ? (
        <Icon />
    ) : (
        <FiberManualRecordIcon
            className={
                state.ui.isOpen.findIndex((id) => id === item?.id) > -1 ? "listCustomIconSubActive" : "listCustomIconSub"
            }
            style={{ fontSize: '8px' }}
        />
    );

    /**
     *nav-dark doesnt exist on navType
     *navType can only be 'light' | 'dark'
     */

    let itemTarget: LinkTarget = '_self';
    if (item.target) {
        itemTarget = '_blank';
    }

    let listItemProps: {
        component: React.ForwardRefExoticComponent<React.RefAttributes<HTMLAnchorElement>> | string;
        href?: string;
        target?: LinkTarget;
    } = { component: React.forwardRef((props, ref) => <Link {...props} to={item.url!} target={itemTarget} />) };
    if (item?.external) {
        listItemProps = { component: 'a', href: item.url, target: itemTarget };
    }

    const itemHandler = (id: string) => {
        setState((prev) => ({ ...prev, ui: {...prev.ui, isOpen: [id]} }));
        matchesSM && setState((prev) => ({ ...prev, ui: {...prev.ui, opened: false} }));
    };

    // active menu item on page load
    React.useEffect(() => {
        if (document.location.pathname.toString()===item.id) {
            setState((prev) => ({ ...prev, ui: {...prev.ui, isOpen: [item.id!]} }));
        }
        // eslint-disable-next-line
    }, []);
    
    return (
        <ListItem
            {...listItemProps}
            disabled={item.disabled}
            className={`menu-item ${level > 1 ? "menu-item-sub" : ""} ${item.extra_css}`}
            selected={state.ui.isOpen.findIndex((id) => id === item.id) > -1}
            onClick={() => itemHandler(item.id!)}
            button
            style={{ paddingLeft: level * 23 + 'px' }}
            data-id={item.extra_id}
        >
            <ListItemIcon className={item?.icon ? "menu-item-icon" : "menu-item-icon menu-item-icon-list"} style={{ minWidth: level > 1 ? 18 : 36 }}>
                {itemIcon}
            </ListItemIcon>
            <ListItemText className="menu-item-text"
                primary={
                    <Typography noWrap variant={state.ui.isOpen.findIndex((id) => id === item.id) > -1 ? 'h5' : 'body1'}>
                        {item.title}
                    </Typography>
                }
                secondary={
                    item.caption && (
                        <Typography variant="caption" className="subMenuCaption" display="block" gutterBottom>
                            {item.caption}
                        </Typography>
                    )
                }
            />
            {item.chip && (
                <Chip
                    color={item.chip.color}
                    variant={item.chip.variant}
                    size={item.chip.size}
                    label={item.chip.label}
                    avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                />
            )}
        </ListItem>
    );
};

export default NavItem;
