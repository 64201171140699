import { Alert } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import Chip from "./Chip";

interface ErrorMessageProps {
  message?: string;
  type?: ErrorMessageType
}
export enum ErrorMessageType {
  Default,
  Chip
};
const ErrorMessage = ({ message, type }: ErrorMessageProps) => {
  switch (type) {
    case ErrorMessageType.Chip:
      return <Chip chipcolor="error" label={message?message:<FormattedMessage id="error.general" />}></Chip>;
  }
  return <Alert icon={false} severity="error">
    {message?message:<FormattedMessage id="error.general" />}
  </Alert>;
};

export default ErrorMessage;