import { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

// project imports
import MainLayout from './../component/layout/MainLayout';
import Loadable from '../component/common/Loadable';
import { useSharedState } from '../state';

const MainPage = Loadable(lazy(() => import("../component/page/Main/Page")));
const ChangeLocalePage = Loadable(lazy(() => import("../component/page/Main/ChangeLocale")));
const ProfilePage = Loadable(lazy(() => import("../component/page/Profile")));
const JobRowListPage = Loadable(lazy(() => import("../component/page/Row/JobRowList")));
const OrganizationEditPage = Loadable(lazy(() => import("../component/page/Organization/OrganizationEdit")));
const JobDashboardPage = Loadable(lazy(() => import("../component/page/Job/JobDashboard")));
const AccountListPage = Loadable(lazy(() => import("../component/page/Account/AccountList")));
const VerificationTemplateListPage = Loadable(lazy(() => import("../component/page/VerificationTemplate/VerificationTemplateList")));
const NotFoundPage = Loadable(lazy(() => import("../component/page/NotFound")));
//-----------------------|| MAIN ROUTING ||-----------------------//

const MainRoutes = () => {
    const location = useLocation();
    const [state] = useSharedState();
    return <MainLayout>
        <Switch location={location} key={location.pathname}>
            <Route path={`/`} exact component={MainPage} />
            <Route path={`/:locale(${state.locales.map(r => r.id).join('|')})`} exact component={ChangeLocalePage} />            
            <Route path="/profile" component={ProfilePage} />
            <Route path="/matching-row/:jobid?/:dates?" component={JobRowListPage} />
            <Route path="/organization/:tag?" component={OrganizationEditPage} />
            <Route path="/job/:id" component={JobDashboardPage} />
            <Route path="/account" component={AccountListPage} />
            <Route path="/verification-template" component={VerificationTemplateListPage} />
            <Route path="*" component={NotFoundPage} />
        </Switch>
    </MainLayout>;
};

export default MainRoutes;
