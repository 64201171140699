import history from "./util/history";
import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline, StyledEngineProvider } from '@material-ui/core';
import Routes from './route';
import theme from './theme';
// project imports
import NavigationScroll from './component/layout/NavigationScroll';
import { Auth0Provider } from "@auth0/auth0-react";
import Locales from "./component/common/Locales";
import { useSharedState } from "./state";

const App = () => {
  const [state] = useSharedState();
  const onRedirectCallback = (appState: any) => {
    history.push(
      appState && appState.returnTo ? appState.returnTo : window.location.pathname
    );
  };

  // Please see https://auth0.github.io/auth0-react/interfaces/auth0_provider.auth0provideroptions.html
  // for a full list of the available properties on the provider

  const providerConfig = {
    domain: state.auth.domain,
    clientId: state.auth.clientId,
    audience: state.auth.audience,
    redirectUri: window.location.origin,
    onRedirectCallback,
  };
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Locales>
          <NavigationScroll>
            <Auth0Provider {...providerConfig}>
              <>
                <Routes />
              </>
            </Auth0Provider>
          </NavigationScroll>
        </Locales>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
