import React from 'react';

// material-ui
import {
    ClickAwayListener,
    IconButton,
    Popper
} from '@material-ui/core';

// project imports
import Transitions from '../../../../common/Transitions';
import NotificationList from './NotificationList';

// assets
import { IconBell } from '@tabler/icons';
import MainCard from '../../../../common/MainCard';

//-----------------------|| NOTIFICATION ||-----------------------//

const NotificationSection = () => {
    const [open, setOpen] = React.useState(false);
    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = React.useRef<any>(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    return (
        <div className="header-notification">
            <IconButton className="btn-action btn-icon-sm"
                ref={anchorRef}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                <IconBell className="icon-default-stroke" />
            </IconButton>
            <Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 15]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions in={open} {...TransitionProps}>
                        <ClickAwayListener onClickAway={handleClose}>
                            <MainCard className="dropdown">
                                <NotificationList />
                            </MainCard>
                        </ClickAwayListener>
                    </Transitions>
                )}
            </Popper>
        </div>
    );
};

export default NotificationSection;
