import { IntlShape } from "react-intl";

export const errorDetail = (err: any, intl: IntlShape) => {
    const errid = err?.message ?? 'error.general';
    if (errid.startsWith('error.')) {
        return intl.formatMessage({
            id: errid
        }, {
            p: err?.detail || ''
        });
    }
    return errid;
}