import React, { Fragment, Ref } from 'react';

// material-ui
import { Card, CardContent, CardHeader, Divider, CardHeaderProps, Box, CardProps } from '@material-ui/core';

// project imports
import { KeyedObject } from '../../types';

// constant
const headerSX = {
    '& .MuiCardHeader-action': { mr: 0 }
};

//-----------------------|| CUSTOM MAIN CARD ||-----------------------//

export interface MainCardProps extends KeyedObject {
    children: React.ReactNode | string;
    content?: boolean;
    sx?: CardProps['sx'];
    secondary?: CardHeaderProps['action'];
    title?: React.ReactNode | string;
    category: string;
}

const MainCard = React.forwardRef(
    (
        {
            children,
            content = true,
            sx = {},
            secondary,
            title,
            category,
            ...others
        }: MainCardProps,
        ref: Ref<HTMLDivElement>
    ) => {
        return (
            <Card className="card-main"
                ref={ref}
                sx={sx}
                {...others}
            >
                {/* card header and action */}
                {title && <div className="card-top">
                    <Fragment>
                        <CardHeader sx={headerSX} title={<Box><div className="card-category">{category}</div><div>{title}</div></Box>} action={secondary} />
                    </Fragment>
                </div>}
                {/* content & header divider */}
                {title && <Divider />}

                {/* card content */}
                {content && (
                    <CardContent>
                        {children}
                    </CardContent>
                )}
                {!content && children}
            </Card>
        );
    }
);

export default MainCard;
