import { useState } from 'react';
import { createContainer } from 'react-tracked';

export interface StateProps {
  locales: {
    id: string
    label: string
  }[]
  rpps: number[];
  rpp_default: number
  auth: {
    clientId: string;
    domain: string;
    audience: string;
  };
  db: {
    url: string;
  }
  //
  ui: UiStateProps;
  hasChanges: boolean
  forceOrganizationId: number | null
  organizations: any[]
  organization: { 
    id: number, 
    name: string,
    first_date: Date | null,
    country: {
      short_name: string
    },
    reload_jobs: number
  } | null;
}

export interface UiStateProps {
  isOpen: string[]
  opened: boolean
  locale: string
}

const initialState: StateProps = {
  locales: [
    { "id": "en", "label": "English" },
    { "id": "sv", "label": "svenska" }
  ],
  rpps: [20,50,100],
  rpp_default: 100,
  auth: {
    domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
    audience: process.env.REACT_APP_AUTH0_AUDIENCE || ''
  },
  db: {
    url: process.env.REACT_APP_DB_URL || ''
  },
  //
  ui: {
    isOpen: [], //for active default menu,
    opened: true,
    locale: localStorage.getItem('selected_locale') || "en"
  },
  organizations: [],
  organization: null,
  forceOrganizationId: null,
  hasChanges: false
};

const useMyState = () => useState(initialState);

export const {
  Provider: SharedStateProvider,
  useTracked: useSharedState,
} = createContainer(useMyState);

export const setReloadJobs = (prev: any) => {
  return {
    ...prev,
    organization: {
        ...prev.organization,
        reload_jobs: (prev.organization.reload_jobs || 0) + 1
    }
}
}