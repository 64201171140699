import React, { useState, useEffect } from 'react';

// third-party
import { IntlProvider, MessageFormatElement } from 'react-intl';
import { useSharedState } from '../../state';

// load locales files
const loadLocaleData = (locale: string) => {
    switch (locale) {
        case 'sv':
            return import('../../locale/sv.json');
        default:
            return import('../../locale/en.json');
    }
};

//-----------------------|| LOCALIZATION ||-----------------------//
export interface LocalsProps {
    children: React.ReactNode;
}

const Locales = ({ children }: LocalsProps) => {
    const [state,] = useSharedState();
    const [messages, setMessages] = useState<Record<string, string> | Record<string, MessageFormatElement[]> | undefined>();

    useEffect(() => {
        loadLocaleData(state.ui.locale).then(
            (d: { default: Record<string, string> | Record<string, MessageFormatElement[]> | undefined }) => {
                setMessages(d.default);
            }
        );
    }, [state.ui.locale]);

    return (
        <React.Fragment>
            {messages && (
                <IntlProvider locale={state.ui.locale} defaultLocale="en" messages={messages}>
                    {children}
                </IntlProvider>
            )}
        </React.Fragment>
    );
};

export default Locales;