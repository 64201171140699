/**
 * MUI Components whose styles are override as per theme
 * @param {JsonObject} theme Plain Json Object
 */

// project imports
import { CustomTypography } from './types';

export const componentStyleOverrides = (theme: CustomTypography) => {
    return {
        MuiAlert: {
            styleOverrides: {
                root: {
                    alignItems: 'center'
                },
                outlined: {
                    border: '1px dashed'
                }
            }
        },
        MuiSlider: {
            styleOverrides: {
                root: {
                    '&.Mui-disabled': {
                        color: theme?.customization?.navType === 'dark' ? theme.colors?.darkTextPrimary! + 50 : theme.colors?.grey300
                    }
                },
                mark: {
                    backgroundColor: theme.paper,
                    width: '4px'
                },
                valueLabel: {
                    color: theme.colors?.primaryMain
                }
            }
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    '&.autocomplete-large': {
                        minWidth: '150px'
                    },               
                    '& .MuiAutocomplete-inputRoot.MuiOutlinedInput-root': {
                        paddingTop: '2px',
                        paddingBottom: '3px'
                    }
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    /** checked not prop
                     *"&.Mui-checked": {
                     *    fontSize: "28px"
                     *}
                     */
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    '&.MuiChip-deletable .MuiChip-deleteIcon': {
                        color: 'inherit'
                    }
                }
            }
        },
        MuiTimelineContent: {
            styleOverrides: {
                root: {
                    color: theme.textDark,
                    fontSize: '16px'
                }
            }
        },
        MuiTreeItem: {
            styleOverrides: {
                label: {
                    marginTop: 14,
                    marginBottom: 14
                }
            }
        },
        MuiTimelineDot: {
            styleOverrides: {
                root: {
                    boxShadow: 'none'
                }
            }
        },
        MuiInternalDateTimePickerTabs: {
            styleOverrides: {
                tabs: {
                    backgroundColor: theme?.customization?.navType === 'dark' ? theme.colors?.darkPaper : theme.colors?.primaryLight,
                    '& .MuiTabs-flexContainer': {
                        borderColor:
                            theme?.customization?.navType === 'dark' ? theme?.colors?.darkTextPrimary! + 20 : theme.colors?.primary200
                    },
                    '& .MuiTab-root': {
                        color: theme?.customization?.navType === 'dark' ? theme.colors?.darkTextSecondary : theme.colors?.grey900
                    },
                    '& .MuiTabs-indicator': {
                        backgroundColor: theme.colors?.primaryDark
                    },
                    '& .Mui-selected': {
                        color: theme.colors?.primaryDark
                    }
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                flexContainer: {
                    borderBottom: '1px solid',
                    borderColor: theme?.customization?.navType === 'dark' ? theme.colors?.darkTextPrimary! + 20 : theme.colors?.grey200
                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    padding: '12px 0 12px 0'
                }
            }
        },       
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    color: theme.paper,
                    background: theme.colors?.grey700
                }
            }
        }
    };
};
