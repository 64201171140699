import moment, { Moment } from 'moment';
const DateFormatDb = 'YYYY-MM-DD';
const DateFormatUi = 'DD/MM/YYYY';
const DateFormatUri = 'DDMMYYYY';
const DatetimeFormatDb = `${DateFormatDb} HH:mm:ss`;
const DatetimeFormatUi = `${DateFormatUi} HH:mm:ss`;
const TimeFormatEnd = ' 23:59:59';

export function formatDateFrom(val: any, fromFormat: string, format: string = DateFormatUi) {
    return val === null ? '' : moment(val, fromFormat).format(format);
};
export function formatDate(val: any, format: string = DateFormatUi) {
    return val === null ? '' : moment(val).format(format);
};

export function formatDatetime(val: any, format: string = DatetimeFormatUi) {
    return val === null ? '' : moment(val).format(format);
};

export function formatMoment(val: Moment | null, format: string = DateFormatUi) {
    return val === null ? '' : val.format(format);
};

export function formatDateDb(val: any, end=false) {
    return `${formatDate(val, DateFormatDb)}${end?TimeFormatEnd:''}`;
};

export function formatDatetimeDb(val: any) {
    return formatDate(val, DatetimeFormatDb);
};

export function formatMomentDb(val: any, end=false) {
    return `${val.format(DateFormatDb)}${end?TimeFormatEnd:''}`;
};

export function parseDates(val: string, defaultval: any) {
    if (val) {
        return val.split('-').map(r => {
            return r===''?null:moment(r, DateFormatUri).toDate();
        });
    }
    return defaultval;
};

export function dateVal(val: any){
    return moment(val).toDate();
}
