// material-ui
import LinearProgress, { LinearProgressProps } from '@material-ui/core/LinearProgress';

//-----------------------|| Loader ||-----------------------//

export interface LoaderProps extends LinearProgressProps {}

const Loader = () => {
    return (
        <div className="loader-container">
            <LinearProgress color="primary" />
        </div>
    );
};

export default Loader;
