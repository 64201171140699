import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import App from './App';
import { Router } from "react-router-dom";
import history from "./util/history";
import { SharedStateProvider } from './state';

// style + assets
import './asset/scss/style.scss';
ReactDOM.render(
  <SharedStateProvider>
    <Router history={history}>
      <App />
    </Router >
  </SharedStateProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
