import { gql, useLazyQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import Loader from "./Loader";
import InputDropdown2 from "./InputDropdown2";
import { Am_Organization } from '../../generated/graphql';
import { useSharedState } from "../../state";
import { Box, Grid } from "@material-ui/core";
import { useEffect } from "react";
import ErrorMessage, { ErrorMessageType } from "./ErrorMessage";
import history from "../../util/history";
import { formatMomentDb } from "../../util/dateutil";
import moment from "moment";
import Dot from "./Dot";
import { useIntl } from "react-intl";

const UserOptions = () => {
    const intl = useIntl();
    const [state, setState] = useSharedState();
    const {
        user
    } = useAuth0();
    //
    const today = moment();
    const lastMonth = moment().subtract(1, 'month');
    const [
        getOrganizations,
        { loading, error, data }
    ] = useLazyQuery(gql`
        query ($external_user_id: String!, $today: timestamptz, $yesterday: timestamptz, $eolm: timestamptz) {
            data: am_user(where: {external_user_id: {_eq: $external_user_id}}) {
                user_organizations {
                    id
                    name
                    first_date
                    up_to_today:organization_no_match_count(args: {_from_date: null, _to_date: $today})
                    up_to_yesterday:organization_no_match_count(args: {_from_date: null, _to_date: $yesterday})
                    up_to_eolm:organization_no_match_count(args: {_from_date: null, _to_date: $eolm})
                    country {
                        short_name
                    }
                }
            }
        }  
        `, {
        variables: {
            external_user_id: user?.sub,
            today: formatMomentDb(today),
            yesterday: formatMomentDb(today.subtract(1, 'days')),
            eolm: lastMonth.format(`YYYY-MM-${lastMonth.daysInMonth()}`)
        }
    });
    useEffect(() => {
        getOrganizations();
    }, [getOrganizations]);
    //
    const handleChangeOrganization = (match: any, dashboard = false) => {
        localStorage.setItem('selected_organization_id', match.id);
        setState((prev) => ({ ...prev, organization: match }));
        if (dashboard) {
            history.push('/');
            setState((prev) => ({ ...prev, ui: {...prev.ui, isOpen: ['/']} }));
        }
    }
    useEffect(() => {
        if (data) {
            setState((prev) => ({ ...prev, organizations: data.data[0].user_organizations }));
            if (data.data[0].user_organizations.length > 0) {                
                let match = data.data[0].user_organizations.find((r: Am_Organization) => `${r.id}` === localStorage.getItem('selected_organization_id'));
                if (match === undefined) {
                    match = data.data[0].user_organizations[0];
                }
                handleChangeOrganization(match);
            }
        }
        // eslint-disable-next-line
    }, [data]);
    useEffect(() => {
        if (state.forceOrganizationId) {
            const match = data.data[0].user_organizations.find((r: Am_Organization) => r.id === state.forceOrganizationId);
            handleChangeOrganization(match, true);
            setState((prev) => ({ ...prev, forceOrganizationId: null }));
        }
        // eslint-disable-next-line
    }, [state.forceOrganizationId]);
    if (loading) {
        return <Loader />;
    }
    if (error) {
        return (<Box m={1}><ErrorMessage type={ErrorMessageType.Chip} /></Box>);
    }
    //
    const onChangeOrganization = (value: number) => {
        if (!state.hasChanges) {
            const match = data.data[0].user_organizations.find((r: Am_Organization) => r.id === value);
            handleChangeOrganization(match, true);
        } else if(window.confirm(intl.formatMessage({id:"confirm.leave"}))){
            setState((prev) => ({ ...prev, hasChanges: false, forceOrganizationId: value }));
        }
    };
    const renderOptions = () => {
        return [
            ...data.data[0].user_organizations.map((r: any) => (
                { id: r.id, label: r.name, htmlLabel: <Dot text={r.name} counts={[r.up_to_today, r.up_to_yesterday, r.up_to_eolm]} /> }
            ))/*,
            { label: 'Create new company...', link: '/organization/new' }*/
        ];
    }
    return (data && state.organization != null) ? <Box className="header-select-organization" display="flex" alignItems="center">
        <Grid container spacing={1} alignItems="center">
            <Grid item>
                <span>{state.hasChanges}</span>
                <InputDropdown2 value={state.organization.id} onChange={onChangeOrganization}
                    options={renderOptions()}></InputDropdown2>
            </Grid>
        </Grid>
    </Box> : null;
};

export default UserOptions;
